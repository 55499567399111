.singlePage {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    padding-bottom: var(--panelHeight);
    /*transition: padding-bottom var(--mediumTime);*/
    opacity: 0;
    transform: translateX(var(--mainTranslate));
    transition: all var(--mediumTime) ease-in;
}

.full {
    padding-bottom: 0
}

.anim{
    opacity: 1;
    transform: translateX(0);
}