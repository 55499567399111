.favPage{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    width: 100%;
    flex: 1;
    background: var(--mainBackground);
    background: cadetblue;
    opacity: 0;
    transform: translateX(var(--mainTranslate));
    transition: all var(--mediumTime) ease-in;
}

.anim{
    opacity: 1;
    transform: translateX(0);
}