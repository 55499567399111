.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    max-width: 1200px;
}

.box {
    opacity: 0;
    transition: all var(--mediumTime) ease-in;
}

.box:nth-child(even) {
    transform: translateX(var(--mainTranslate));
}

.box:nth-child(odd) {
    transform: translateX(var(--mainTranslateM));
}

.box + .box {
    margin-top: 3px;
}

.padd {
    padding-bottom: var(--panelHeight);
}

.contentBox {
    width: 100%;
    min-height: 230px;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    position: relative;
}

.contentBox > .title {
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 7px 20px;
    /*background: rgba(4, 4, 4, 0.45);*/
    /*box-shadow: 0 0 3px 4px rgba(4, 4, 4, 0.45);*/
    color: var(--white);
    /*font-size: 20px;*/
    font-size: 6vw;
    /*font-weight: bold;*/
    border-top-left-radius: 10px;
}

.anim:nth-child(n) {
    opacity: 1;
    transform: translateX(0);
}
