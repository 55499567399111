.gContent {
    display: flex;
    flex-direction: column;
    width: 100%;
}


.box {
    display: flex;
    width: 100%;
    height: 230px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;
    transition: all var(--mediumTime) ease-in;
    position: relative;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #fff;
}

.box:nth-child(even) {
    transform: translateX(var(--mainTranslate));
}

.box:nth-child(odd) {
    transform: translateX(var(--mainTranslateM));
}

.box + .box {
    margin-top: 3px;
}

.anim:nth-child(n) {
    opacity: 1;
    transform: translateX(0);
    /*animation: show var(--mediumTime) forwards;*/
}

.anim:nth-child(n) {
    opacity: 1;
    transform: translateX(0);
}


