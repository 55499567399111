@import url(https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@1,600&family=Montserrat:wght@600;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --mainBackground: #444;
    --hoverFilter: brightness(120%);
    --activeFilter: brightness(90%);
    --hoverTransition: filter 0.3s;
    --iconColor: floralwhite;
    --panelHeight: 50px;
    --transparentBackground: rgba(4, 4, 4, 0.5);
    --mediumTime: 0.6s;
    --white: #fbfbfb;
    --jotColor: #b2b2b2;
    --mainTranslate: 50%;
    --mainTranslateM: -50%;
    --mainYellow: #fec903;
    --mapHeight: 230px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

.App {
    text-align: center;
    background: #444;
    background: var(--mainBackground);
    /*min-height: 100vh;*/
    width: 100vw;
    overflow-x: hidden;
    font-family: "Montserrat", sans-serif
}

.lock {
    overflow: hidden;
}
h1{
    color: white;
}

.Main_main__2YBMo {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    position: relative;
    transition: 0.6s;
}

.Main_pad__19_Bp{
    padding-top: var(--mapHeight);
}


.Content_content__2116h {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    max-width: 1200px;
}

.Content_box__2Y3C5 {
    opacity: 0;
    transition: all var(--mediumTime) ease-in;
}

.Content_box__2Y3C5:nth-child(even) {
    -webkit-transform: translateX(var(--mainTranslate));
            transform: translateX(var(--mainTranslate));
}

.Content_box__2Y3C5:nth-child(odd) {
    -webkit-transform: translateX(var(--mainTranslateM));
            transform: translateX(var(--mainTranslateM));
}

.Content_box__2Y3C5 + .Content_box__2Y3C5 {
    margin-top: 3px;
}

.Content_padd__2GmLR {
    padding-bottom: var(--panelHeight);
}

.Content_contentBox__3vZFS {
    width: 100%;
    min-height: 230px;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    position: relative;
}

.Content_contentBox__3vZFS > .Content_title__3x6lZ {
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 7px 20px;
    /*background: rgba(4, 4, 4, 0.45);*/
    /*box-shadow: 0 0 3px 4px rgba(4, 4, 4, 0.45);*/
    color: var(--white);
    /*font-size: 20px;*/
    font-size: 6vw;
    /*font-weight: bold;*/
    border-top-left-radius: 10px;
}

.Content_anim__1U8-t:nth-child(n) {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.generalPage_gContent__3rUR4 {
    display: flex;
    flex-direction: column;
    width: 100%;
}


.generalPage_box__3GB93 {
    display: flex;
    width: 100%;
    height: 230px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;
    transition: all var(--mediumTime) ease-in;
    position: relative;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #fff;
}

.generalPage_box__3GB93:nth-child(even) {
    -webkit-transform: translateX(var(--mainTranslate));
            transform: translateX(var(--mainTranslate));
}

.generalPage_box__3GB93:nth-child(odd) {
    -webkit-transform: translateX(var(--mainTranslateM));
            transform: translateX(var(--mainTranslateM));
}

.generalPage_box__3GB93 + .generalPage_box__3GB93 {
    margin-top: 3px;
}

.generalPage_anim__3Js1d:nth-child(n) {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    /*animation: show var(--mediumTime) forwards;*/
}

.generalPage_anim__3Js1d:nth-child(n) {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
}



.favoritePage_favPage__1g5Bc{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    width: 100%;
    flex: 1 1;
    background: var(--mainBackground);
    background: cadetblue;
    opacity: 0;
    -webkit-transform: translateX(var(--mainTranslate));
            transform: translateX(var(--mainTranslate));
    transition: all var(--mediumTime) ease-in;
}

.favoritePage_anim__GWy3_{
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
}
.RecordPage_recPage__35Xru{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    width: 100%;
    flex: 1 1;
    background: var(--mainBackground);
    background: indianred;
    opacity: 0;
    -webkit-transform: translateX(var(--mainTranslate));
            transform: translateX(var(--mainTranslate));
    transition: all var(--mediumTime) ease-in;
}
.RecordPage_anim__1LlrF{
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
}
.SinglePage_singlePage__3WMbC {
    display: flex;
    flex: 1 1;
    width: 100%;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    padding-bottom: var(--panelHeight);
    /*transition: padding-bottom var(--mediumTime);*/
    opacity: 0;
    -webkit-transform: translateX(var(--mainTranslate));
            transform: translateX(var(--mainTranslate));
    transition: all var(--mediumTime) ease-in;
}

.SinglePage_full__1dUrK {
    padding-bottom: 0
}

.SinglePage_anim__1hkGO{
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
}
