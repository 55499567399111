@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@1,600&family=Montserrat:wght@600;800&display=swap');


:root {
    --mainBackground: #444;
    --hoverFilter: brightness(120%);
    --activeFilter: brightness(90%);
    --hoverTransition: filter 0.3s;
    --iconColor: floralwhite;
    --panelHeight: 50px;
    --transparentBackground: rgba(4, 4, 4, 0.5);
    --mediumTime: 0.6s;
    --white: #fbfbfb;
    --jotColor: #b2b2b2;
    --mainTranslate: 50%;
    --mainTranslateM: -50%;
    --mainYellow: #fec903;
    --mapHeight: 230px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

.App {
    text-align: center;
    background: var(--mainBackground);
    /*min-height: 100vh;*/
    width: 100vw;
    overflow-x: hidden;
    font-family: "Montserrat", sans-serif
}

.lock {
    overflow: hidden;
}
h1{
    color: white;
}
