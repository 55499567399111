.main {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    position: relative;
    transition: 0.6s;
}

.pad{
    padding-top: var(--mapHeight);
}

